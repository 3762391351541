import React, { useState, useRef, useEffect } from "react";
import "./ImagesSlider.css";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  // Effet pour synchroniser le défilement du slider avec currentIndex
  useEffect(() => {
    if (sliderRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      sliderRef.current.scrollTo({
        left: currentIndex * (sliderWidth / 2),
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  // Fonction pour se déplacer vers un index particulier
  const goToIndex = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="slider-container">
      <div className="slider" ref={sliderRef}>
        {images.map((image, index) => (
          <div key={index} className="slider-item">
            <div 
                            className="slider-overlay"
                            style={{ backgroundColor: image.overlayColor }}
                        ></div>
            <img src={image.src} alt={image.alt} className="slider-image" />
            <div className="slider-caption">{image.caption}</div>
          </div>
        ))}
      </div>
      <div className="dots">
        {images.map((_, index) => {
          if (index % 2 === 0) {
            // Afficher un dot pour chaque groupe de deux images
            return (
              <span
                key={index}
                className={`dot ${currentIndex === index ? "active" : ""}`}
                onClick={() => goToIndex(index)}
              ></span>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
export default ImageSlider;
